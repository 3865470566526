.boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--btn);
	color: #fff;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}

.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */

.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */

.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */

.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.boton.three:hover::after {
	transform: scale(400);
	background: var(--btn-hover);
}

/*boton del formulario */

.formContent__btn{
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}



/* CSS */
.boton.five {
  align-items: center;
  appearance: none;
  background-color: var(--btn);
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.boton.five:active,
.boton.five:focus {
  outline: none;
}

.boton.five:hover {
  background-position: -20px -20px;
}

.boton.five:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}